import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Layout from './Layout';
import CustomLoader from '../common/CustomLoader';

export class AfterCheckout extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { actions } = this.props;
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('success');
    if (status === 'true') {
      const obj = {
        session_id: queryParams.get('session_id'),
      };
      actions.verifyPaymentStatus(obj).then(() => {
        actions.clientDetails();
        actions.clientOrganization();
      });
    }
  }

  render() {
    const { verifyPaymentStatusPending, afterPaymentResponse, verifyPaymentStatusError } =
      this.props.home;
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('success');
    return (
      <Layout currentPage="Payment Response">
        <div className="mt-5">
          <div className="mx-3 mx-md-4">
            <div className="row gx-4 mb-2">
              <div className="col-12 col-xl-10 offset-xl-1">
                <div className="card card-plain shadow-lg">
                  <div className="card-body">
                    <h5 className="font-weight-bolder">Payment Response</h5>
                    <hr className="dark horizontal my-4" />
                    <div className="text-center">
                      {verifyPaymentStatusPending && (
                        <>
                          <p className="fs-3">
                            Please wait while we are checking the payment status
                          </p>
                          <CustomLoader
                            loading={verifyPaymentStatusPending}
                            size="15px"
                            color="#303453"
                            sync={true}
                          />
                        </>
                      )}
                      {afterPaymentResponse && (
                        <>
                          <div className="flex align-items-center text-success">
                            <i className="material-icons opacity-10 fs-1">check_circle</i> &nbsp;
                            <p className="fs-4">{afterPaymentResponse}</p>
                          </div>
                        </>
                      )}
                      {verifyPaymentStatusError && (
                        <>
                          <div className="flex align-items-center text-danger">
                            <i className="material-icons opacity-10 fs-1">cancel</i> &nbsp;
                            <p className="fs-4">{verifyPaymentStatusError}</p>
                          </div>
                        </>
                      )}
                      {status === 'false' && (
                        <>
                          <div className="flex align-items-center text-danger">
                            <i className="material-icons opacity-10 fs-1">cancel</i> &nbsp;
                            <p className="fs-3">
                              Your payment failed. Please try again from plan & billing section.
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AfterCheckout);
