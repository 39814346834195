import {
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  ConfirmClientRegister,
  Calculator,
} from './';

export default {
  path: '/',
  name: 'Login',
  childRoutes: [
    { path: '/', name: 'Login', component: Login },
    { path: '/register', name: 'Register', component: Register },
    { path: '/forgot-password', name: 'Forgot password', component: ForgotPassword },
    { path: '/reset-password/:id', name: 'Reset password', component: ResetPassword },
    {
      path: '/register/confirm/:id',
      name: 'Confirm client register',
      component: ConfirmClientRegister,
    },
    {
      path: '/software-development-cost-calculator',
      name: 'Calculator',
      component: Calculator,
      isPrivate: false,
    },
  ],
};
